.textGradient {
  background: linear-gradient(90deg, #e55658 0%, #791e33 99.87%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bgColor {
  background: #fc3b3b;
  box-shadow: 8px 1px 133.3px 106px #a30b00;
}

.bgGradient {
  background: linear-gradient(
    93deg,
    #e40512 0.01%,
    rgba(253, 101, 111, 0.91) 100%
  );
}

.textGradient1 {
  background: linear-gradient(93deg, #de3f49 0.34%, #fd7580 106.47%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* 
.nvBg{
  background: url('./assets/newImages for home/Vector\ Smart\ Object\ \(Double\ Click\ to\ Edit\)_.png');
} */

/* card */
.card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
}

.register {
  background-image: url("./assets/newImages for home/Grand-Hyatt-Kochi-Bolgatty-P058-Hotel-Facade.16x9.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.cardData {
  width: 300px;
  height: 350px;
  flex-shrink: 0;
  filter: drop-shadow(0px 5px 22.5px rgba(0, 0, 0, 0.11));
}


